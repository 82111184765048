<template>
  <section class="m-profile manage-p">
    <b-container class="h-100">
      <b-row class="align-items-center justify-content-center h-100">
        <b-col lg="10">
          <div class="sign-user_card">
            <b-row>
              <b-col lg="2">
                <div class="upload_profile d-inline-block">
                  <img v-bind:src="profilePicture" class="profile-pic rounded-circle img-fluid" alt="user" />
                </div>
              </b-col>
              <b-col lg="10" class="device-margin">
                <div class="profile-from">
                  <h4 class="mb-3">Mi Perfil</h4>
                  <b-form class="mt-4">
                    <b-form-group>
                      <label>Numero de Identificación</label>
                      <b-form-input v-model="userFirebaseInfo.idNumber" type="number" class="form-control mb-0"
                        id="idNumber" placeholder="Digita tu ID" autocomplete="off" :disabled="true" required />
                    </b-form-group>
                    <b-form-group>
                      <label>Email</label>
                      <b-form-input v-model="userFirebaseInfo.authEmail" type="text" class="form-control mb-0" id="email"
                        placeholder="Digita tu correo" autocomplete="off" :disabled="true" required />
                    </b-form-group>
                    <b-form-group>
                      <label>Nombres</label>
                      <b-form-input v-model="userFirebaseInfo.name" type="text" class="form-control mb-0" id="nombres"
                        placeholder="Digita tus nombres" autocomplete="off" required />
                      <br />
                      <label>Apellidos</label>
                      <b-form-input v-model="userFirebaseInfo.lastName" type="text" class="form-control mb-0"
                        id="apellidos" placeholder="Digita tus apellidos" autocomplete="off" required />
                    </b-form-group>
                    <b-form-group>
                      <label>Fecha de nacimiento</label>
                      <flat-pickr v-model="date" :config="config" class="form-control date-input basicFlatpickr mb-0"
                        placeholder="Selecciona la fecha" />
                    </b-form-group>
                    <label>Genero</label>
                    <b-form-group class="d-flex flex-md-row flex-column">
                      <div class="iq-custom-select d-inline-block manage-gen">
                        <b-form-select v-model="gender" :options="options"
                          class="form-control pro-dropdown"></b-form-select>
                      </div>
                    </b-form-group>
                    <div class="row">
                      <div class="col">
                        <button class="btn btn-hover d-flex" v-on:click.prevent="guardarDatos()">
                          Guardar
                        </button>
                      </div>
                      <div class="col">
                        <a href="javascript:history.back()" class="btn btn-link">Cancelar</a>
                      </div>
                    </div>
                  </b-form>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import { photoUser } from "../../../const/constant"
import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/storage"

const db = firebase.firestore()

export default {
  name: "ManageProfile",
  components: {},
  data() {
    return {
      photoUser: "",
      profilePicture: photoUser,
      userFirebaseInfo: {},
      userInfo: {},
      gender: "masculino",
      date: null,
      docId: "",
      options: [
        { value: "masculino", text: "masculino" },
        { value: "femenino", text: "femenino" },
        { value: "Otro", text: "Otro" }
      ],
      config: {
        dateFormat: "M/D/Y"
      },
      imagenes: [],
      imagen: null
    }
  },
  mounted() {
    this.userInfo = this.$store.getters["Setting/authUserState"]
    console.log(this.userInfo)
    if (this.userInfo.user.authEmail) {
      this.userFirebaseInfo = this.userInfo.user
    }
    this.profilePicture = photoUser
  },
  methods: {
    guardarDatos() {
      const email = this.userFirebaseInfo.authEmail
      const providerId = this.userFirebaseInfo.providerLogin
      this.$store
        .dispatch("Setting/getUserEmailInfo", {
          db,
          email,
          providerId
        })
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.docId = doc.id
          })
          return this.docId
        })
        .then((docId) => {
          db.collection("usuarios")
            .doc(docId)
            .update({
              photoUrl: this.profilePicture,
              name: this.userFirebaseInfo.name,
              lastName: this.userFirebaseInfo.lastName,
              dateBirth: this.date,
              gender: this.gender
            })
            .then(() => {
              console.log("Document successfully updated!")
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error)
            })
        })
      /**/
    }
    /* subirImagen(e) {
      this.imagen = e.target.files[0]
      const storageRef = storage.ref()
      const imageRef = storageRef.child(
        "/assets/images/user/" + this.imagen.name
      )
      var metadata = {
        contentType: "image/jpeg"
      }
      imageRef.put(this.imagen, metadata)
      storageRef
        .child("/assets/images/user/" + this.imagen.name)
        .getDownloadURL()
        .then((url) => {
          this.profilePicture = url
          console.log(url)
        })
    } */
  }
}
</script>
<style scoped>
.image-upload {
  position: absolute;
  top: 0;
  opacity: 0;
}
</style>
